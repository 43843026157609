import { useState } from "react";
import LogoImg from "../../assets/img/logo-menu.svg";
import CloseIcon from "../../assets/img/x-preto.svg";
import { UseModal } from "../../Hook/Usemodal";
import { api } from "../../services";
import { SimpleButton } from "../SimpleButton";
import { DocumentIcon } from "./DocumentIcon";
import { SelfieIcon } from "./SelfieIcon";
import {
    Container,
    FileName,
    LogoContainer,
    ModalContent,
    UploadDocCard,
} from "./styles";
import { formataCPF } from "../../util/helper";

export const VerificacaoDocumentoIdWall = () => {
    const { handleOpen, state } = UseModal();
    const currentDocument = formataCPF(state?.dados?.document || "00925490040");

    const [document, setDocument] = useState<File>();
    const [selfie, setSelfie] = useState<File>();
    const hasArrPhoto = ["png", "jpg", "jpeg"];
    const typeDocument = document?.type.split("/")[1] || "";
    const selfieType = selfie?.type.split("/")[1] || "";

    function handleGetDocument(e: any) {
        const files = e.target.files;

        setDocument(files[0]);
    }

    function handleGetSelfie(e: any) {
        const files = e.target.files;

        setSelfie(files[0]);
    }

    async function handleSendDocuments() {
        const formData = new FormData();
        formData.append("document", currentDocument);
        if (selfie) formData.append("selfie", selfie);
        if (document) formData.append("file", document);

        await api.request({
            url: "/whitelist-sent-document",
            method: "post",
            data: formData,
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });

        handleOpen("documentoEmAnaliseIdWall");
    }

    return (
        <Container>
            <LogoContainer>
                <img src={LogoImg} alt="logo" />
            </LogoContainer>

            <ModalContent>
                <h1>verificação de segurança</h1>

                <UploadDocCard>
                    <div className="info">
                        <DocumentIcon />

                        <div>
                            <p className="enabled">Envie um documento</p>
                            <p>
                                Será necessário enviar uma foto legível do seu{" "}
                                <b>RG ou RNE</b> para validarmos seu cadastro.
                            </p>
                        </div>
                    </div>
                    <input
                        type="file"
                        id="document"
                        accept=".jpg, .jpeg, .png"
                        onChange={handleGetDocument}
                    />

                    {document ? (
                        <FileName>
                            {hasArrPhoto.includes(typeDocument)
                                ? `${currentDocument} - ${typeDocument}`
                                : document.name}

                            <button onClick={() => setDocument(undefined)}>
                                <img src={CloseIcon} alt="close" />
                            </button>
                        </FileName>
                    ) : (
                        <label htmlFor="document">
                            <SimpleButton>
                                Enviar foto do documento
                            </SimpleButton>
                        </label>
                    )}
                </UploadDocCard>

                <UploadDocCard>
                    <div className="info">
                        <SelfieIcon active={!!document} />

                        <div>
                            <p className={document ? "enabled" : ""}>
                                Envie uma selfie com o documento
                            </p>
                            <p>
                                Agora envie uma selfie sua, mostrando o mesmo
                                documento enviado.
                            </p>
                        </div>
                    </div>
                    <input
                        type="file"
                        id="selfie"
                        accept=".jpg, .jpeg, .png"
                        disabled={!document}
                        onChange={handleGetSelfie}
                    />

                    {selfie ? (
                        <FileName>
                            {hasArrPhoto.includes(selfieType)
                                ? `${currentDocument} - ${selfieType}`
                                : selfie.name}

                            <button onClick={() => setSelfie(undefined)}>
                                <img src={CloseIcon} alt="close" />
                            </button>
                        </FileName>
                    ) : (
                        <label htmlFor="selfie">
                            <SimpleButton disabled={!document}>
                                enviar selfie
                            </SimpleButton>
                        </label>
                    )}
                </UploadDocCard>

                {document && selfie && (
                    <SimpleButton onClick={handleSendDocuments}>
                        Enviar
                    </SimpleButton>
                )}
            </ModalContent>
        </Container>
    );
};
