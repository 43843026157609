import Table, { Pagination } from "../../../Table";
import { Loader } from "../../../../Loader";
import { useWhiteList } from "../../../../../Hook/system/useWhiteList";

import { Data } from "../../config";

import imgLixeira from "../../../../../assets/img/lixeira-white-list.svg";
import { formatDateSystem, formataCPF } from "../../../../../util/helper";
import { GridRenderCellParams, GridColumns } from "@mui/x-data-grid-pro";

export function AprovadosWhiteList() {
    const { loading, whiteLists, setExcluirItem, pagination, getData } =
        useWhiteList();

    const rows: Data[] = whiteLists?.map((list) => {
        return {
            id: list.id + "",
            approved_user: list?.approved_user?.name || "",
            reproved_user: list?.reproved_user?.name || "",
            cpf: list.document ? formataCPF(list.document) : "",
            nome: list?.name || "",
            data_inclusao: formatDateSystem(list.created_at),
            data_validacao: formatDateSystem(list.validated_at || ""),
            incluido_por: list?.user?.name || "",
            ver_documento: "",
            reproved_reason: list?.reproved_reason || "",
            excluir: "",
        };
    });

    const collumns: GridColumns = [
        {
            field: "id",
            headerName: "ID",
        },
        {
            field: "cpf",
            headerName: "CPF",
        },
        {
            field: "nome",
            headerName: "NOME",
        },
        {
            field: "data_inclusao",
            headerName: "DATA DE INCLUSÃO",
        },

        {
            field: "approved_user",
            headerName: "VALIDAÇÃO",
        },

        {
            field: "data_validacao",
            headerName: "DATA DA VALIDAÇÃO",
        },

        {
            field: "excluir",
            headerName: "EXCLUIR",
            flex: 1,
            renderCell: ({ row }: GridRenderCellParams) => {
                return (
                    <button
                        onClick={() =>
                            setExcluirItem({
                                active: true,
                                id: row.id,
                            })
                        }
                    >
                        <img src={imgLixeira} alt="" />
                    </button>
                );
            },
        },
    ];

    return (
        <>
            {loading ? (
                <div className="container-loading">
                    <Loader />
                </div>
            ) : (
                <Table
                    columns={collumns}
                    rows={rows ? rows : []}
                    nameCSV="ADICIONAR-CAMPANHA"
                />
            )}

            {pagination?.last_page > 1 ? (
                <Pagination
                    total={pagination?.last_page}
                    currentPage={async (page) => {
                        await getData(page);
                    }}
                />
            ) : null}
        </>
    );
}
