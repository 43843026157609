import { ErrorMessage, Field, Form, Formik, FormikProps } from "formik";
import { motion } from "framer-motion";
import { ChangeEvent, KeyboardEvent, useState } from "react";
import Mask from "react-input-mask";
import { api } from "../../../services";
import { convertDateDB, maskDateRegex } from "../../../util/helper";

import imgOlho from "../../../assets/img/olho.svg";
import Select, { options } from "../../NovoSelect";
import { Button } from "../Button";

import { toast } from "react-toastify";
import { useFormLogin } from "../../../Hook/user/useFormLogin";
import { CheckPassword } from "../../CheckPassword";
import { register, Register, validationRegister } from "./config";

import { UseModal } from "../../../Hook/Usemodal";

interface FormRegisterProps {
    setRegister?: (value: boolean) => void;
}

interface IpreRegister {
    tipo: string;
    name: string;
    document: string;
    apelido: string;
    data_nascimento: string;
    genero: string;
    email: string;
    phone_number: string;
    password: string;
    password_confirmation: string;
}

export function CompleteRegister(props: FormRegisterProps) {
    const { state, setState } = useFormLogin();
    const { handleOpen } = UseModal();
    const [olhoSenha, setOlhoSenha] = useState(false);
    const [loading, setLoading] = useState(false);

    const genero: options[] = [
        {
            label: "Masculino",
            value: 1,
        },
        {
            label: "Feminino",
            value: 2,
        },
        {
            label: "Outro",
            value: 3,
        },
    ];

    async function preCadastro(values: IpreRegister) {
        setLoading(true);
        try {
            const response = await api.post<IpreRegister>("pre-register", {
                ...values,
            });
            setLoading(false);
            return response;
        } catch (error: any) {
            console.log(error);
            setLoading(false);
            return error.response;
        }
    }

    return (
        <Formik
            enableReinitialize
            validateOnMount
            initialValues={{ ...register, ...state }}
            validationSchema={validationRegister}
            onSubmit={async (values, actions) => {
                const phone_number =
                    "55" + values.phone_number.replace(/\D/g, "");

                const objFormated = {
                    apelido: values.apelido,
                    data_nascimento: convertDateDB(values.data_nascimento),
                    document: state.document,
                    email: values.email,
                    genero: values.genero,
                    name: values.name,
                    phone_number,
                    tipo: state.document.length <= 11 ? "fisica" : "juridica",
                    password: values.password,
                    password_confirmation: values.password_confirmation,
                };

                const preCadastroResponse = await preCadastro(objFormated);

                if (preCadastroResponse.status !== 200) return;

                setState({
                    ...state,
                    ...objFormated,
                });

                ///src/pages/IdWall/index.tsx
                handleOpen("idWall", { ...values });
            }}
        >
            {(props: FormikProps<Register>) => {
                const {
                    values,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    setFieldTouched,
                } = props;

                const { phone_number, confirm_phone_number, name } = values;

                return (
                    <Form>
                        <motion.div
                            animate={{
                                scale: [0.1, 1],
                            }}
                            transition={{
                                duration: 0.1,
                            }}
                            className={`container-input user ${
                                state.name ? "active disabled" : ""
                            }`}
                        >
                            <Field
                                name="name"
                                value={name}
                                placeholder="NOME COMPLETO"
                            />
                        </motion.div>
                        <ErrorMessage name="name" component="span" />

                        <motion.div
                            animate={{
                                scale: [0.1, 1],
                            }}
                            transition={{
                                duration: 0.1,
                            }}
                            className={`container-input user active disabled`}
                        >
                            <Field
                                name="name"
                                value={values.document}
                                placeholder="CPF/CNPJ"
                            />
                        </motion.div>
                        <ErrorMessage name="document" component="span" />

                        <motion.div
                            animate={{
                                scale: [0.1, 1],
                            }}
                            transition={{
                                duration: 0.3,
                            }}
                            className={`container-input ${
                                props.errors.apelido && props.touched.apelido
                                    ? "error"
                                    : ""
                            } user`}
                        >
                            <Field name="apelido" placeholder="APELIDO" />
                        </motion.div>
                        <ErrorMessage name="apelido" component="span" />

                        <motion.div
                            animate={{
                                scale: [0.1, 1],
                            }}
                            transition={{
                                duration: 0.4,
                            }}
                            className={`container-input ${
                                props.errors.data_nascimento &&
                                props.touched.data_nascimento
                            } calendario`}
                        >
                            <Field
                                name="data_nascimento"
                                placeholder="DATA DE NASCIMENTO"
                                type="tel"
                                value={values.data_nascimento}
                                onChange={(
                                    e: ChangeEvent<HTMLInputElement>
                                ) => {
                                    if (e.target.value.length <= 10) {
                                        setFieldValue(
                                            "data_nascimento",
                                            maskDateRegex(e.target.value)
                                        );
                                    }
                                }}
                            />
                        </motion.div>

                        <ErrorMessage name="data_nascimento" component="span" />

                        <motion.div
                            animate={{
                                scale: [0.1, 1],
                            }}
                            transition={{
                                duration: 0.5,
                            }}
                            className={`container-input genero ${
                                props.errors.genero && props.touched.genero
                                    ? "error genero"
                                    : ""
                            }`}
                        >
                            <Select
                                tipo="user"
                                label="GÊNERO"
                                options={genero}
                                handleBlur={() =>
                                    setFieldTouched("genero", true)
                                }
                                onChange={(values) =>
                                    setFieldValue(
                                        "genero",
                                        values.label.toUpperCase()
                                    )
                                }
                            />
                        </motion.div>
                        <ErrorMessage name="genero" component="span" />

                        <motion.div
                            animate={{
                                scale: [0.1, 1],
                            }}
                            transition={{
                                duration: 0.7,
                            }}
                            className={`container-input contato ${
                                props.errors.email && props.touched.email
                                    ? "error"
                                    : ""
                            }`}
                        >
                            <Field
                                name="email"
                                type="text"
                                placeholder="E-MAIL"
                                onCut={(e: KeyboardEvent<HTMLInputElement>) => {
                                    if (!e.ctrlKey) {
                                        toast.error("Ação não permitida");
                                    }
                                    e.preventDefault();
                                }}
                                onCopy={(
                                    e: KeyboardEvent<HTMLInputElement>
                                ) => {
                                    if (!e.ctrlKey) {
                                        toast.error("Ação não permitida");
                                    }

                                    e.preventDefault();
                                }}
                                onPaste={(
                                    e: KeyboardEvent<HTMLInputElement>
                                ) => {
                                    if (!e.ctrlKey) {
                                        toast.error("Ação não permitida");
                                    }
                                    e.preventDefault();
                                }}
                            />
                        </motion.div>
                        <ErrorMessage name="email" component="span" />

                        <motion.div
                            animate={{
                                scale: [0.1, 1],
                            }}
                            transition={{
                                duration: 0.8,
                            }}
                            className={`container-input ${
                                props.errors.email_confirmation &&
                                props.touched.email_confirmation
                                    ? "error"
                                    : ""
                            } contato`}
                        >
                            <Field
                                name="email_confirmation"
                                type="text"
                                placeholder="CONFIRMAR E-MAIL"
                                onCut={(e: KeyboardEvent<HTMLInputElement>) => {
                                    if (!e.ctrlKey)
                                        toast.error("Ação não permitida");
                                    e.preventDefault();
                                }}
                                onCopy={(
                                    e: KeyboardEvent<HTMLInputElement>
                                ) => {
                                    if (!e.ctrlKey)
                                        toast.error("Ação não permitida");
                                    e.preventDefault();
                                }}
                                onPaste={(
                                    e: KeyboardEvent<HTMLInputElement>
                                ) => {
                                    if (!e.ctrlKey)
                                        toast.error("Ação não permitida");
                                    e.preventDefault();
                                }}
                            />
                        </motion.div>
                        <ErrorMessage
                            name="email_confirmation"
                            component="span"
                        />

                        <motion.div
                            animate={{
                                scale: [0.1, 1],
                            }}
                            transition={{
                                duration: 0.9,
                            }}
                            className={`container-input ${
                                props.errors.phone_number &&
                                props.touched.phone_number
                                    ? "error"
                                    : ""
                            } celular`}
                        >
                            <Mask
                                mask="(99) 99999-9999"
                                placeholder="CELULAR"
                                name="phone_number"
                                type="tel"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={phone_number}
                            />
                        </motion.div>
                        <ErrorMessage name="phone_number" component="span" />

                        <motion.div
                            animate={{
                                scale: [0.1, 1],
                            }}
                            transition={{
                                duration: 1,
                            }}
                            className={`container-input ${
                                props.errors.confirm_phone_number &&
                                props.touched.confirm_phone_number
                                    ? "error"
                                    : ""
                            } celular`}
                        >
                            <Mask
                                mask="(99) 99999-9999"
                                placeholder="CONFIRMAR CELULAR"
                                name="confirm_phone_number"
                                type="tel"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={confirm_phone_number}
                            />
                        </motion.div>
                        <ErrorMessage
                            name="confirm_phone_number"
                            component="span"
                        />

                        {/* <Text
                            as="small"
                            className="informacao-senha"
                            m="17px 0px 32px 0px"
                        >
                            Sua senha deve ser numérica e possuir 8 dígitos.
                        </Text> */}

                        <motion.div
                            animate={{
                                scale: [0.1, 1],
                            }}
                            transition={{
                                duration: 1.1,
                            }}
                            className={`container-input olho ${
                                props.errors.password && props.touched.password
                                    ? "error"
                                    : ""
                            } senha`}
                        >
                            <Field
                                name="password"
                                type={`${olhoSenha ? "text" : "password"}`}
                                placeholder="CRIAR SENHA DE ACESSO"
                            />

                            <button
                                type="button"
                                className={`${olhoSenha ? "active" : ""} `}
                                onClick={() => setOlhoSenha(!olhoSenha)}
                            >
                                <img src={imgOlho} alt="" />
                            </button>
                        </motion.div>

                        <ErrorMessage name="password" component="span" />

                        <motion.div
                            animate={{
                                scale: [0.1, 1],
                            }}
                            transition={{
                                duration: 1.2,
                            }}
                            className={`container-input olho ${
                                props.errors.password_confirmation &&
                                props.touched.password_confirmation
                                    ? "error"
                                    : ""
                            } senha`}
                        >
                            <Field
                                name="password_confirmation"
                                type={`${olhoSenha ? "text" : "password"}`}
                                placeholder="CONFIRMAR SENHA"
                            />

                            <button
                                type="button"
                                className={`${olhoSenha ? "active" : ""} `}
                                onClick={() => setOlhoSenha(!olhoSenha)}
                            >
                                <img src={imgOlho} alt="" />
                            </button>
                        </motion.div>
                        <ErrorMessage
                            name="password_confirmation"
                            component="span"
                        />

                        <CheckPassword values={values.password} />

                        <motion.div
                            whileHover={{ scale: 1 }}
                            whileTap={{ scale: 0.5 }}
                            transition={{
                                duration: 1,
                            }}
                            className="mt-auto"
                        >
                            <Button
                                type="submit"
                                disabled={!props.isValid}
                                isLoading={loading}
                                spiner="BallTriangle"
                                spinerColor="primary"
                            >
                                AVANÇAR
                            </Button>
                        </motion.div>
                    </Form>
                );
            }}
        </Formik>
    );
}
